import cn from 'classnames'
import React, { useEffect, useState, useRef } from 'react'
import Cookies from 'js-cookie'
import Image from 'next/image'
import { motion } from 'framer-motion'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { useRouter } from 'next/router'
import Picture from '@components/ui/Picture'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { XMarkIcon } from '@heroicons/react/24/solid'
import Subscribe from '@components/common/Subscribe'
import s from './PopSales.module.css'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const PopSales = ({ open, setOpen, lang, metafields }) => {
  const { setRelativeLink } = useRelativeLink()
  const contentRef = useRef()
  const { locale, pathname, asPath } = useRouter()
  const [data, setData] = useState({})

  const initOpenPop = () => {
    const path = asPath?.split('?')?.[0]
    let data
    lang?.lists?.map((item) => {
      if (
        item?.receivedURL?.find((item) => item === path || item === pathname)
      ) {
        data = item
      } else if (item?.totalStation) {
        data = item
      }
    })
    const initPopSales = Cookies.get('initPopSales')
    if (
      (data?.intervalAfterClosing || data?.intervalAfterOpening) &&
      initPopSales === 'hidden'
    ) {
      return false
    }
    if (data) {
      setData(data)
      if (data?.urlTriggerAction) {
        return asPath?.includes(data.urlTriggerAction)
      } else {
        return true
      }
    }
  }

  const closePop = () => {
    setOpen(false)
    clearAllBodyScrollLocks()
    if (data?.intervalAfterClosing > 0) {
      const hostUrl = window?.location?.host?.split('.') || 'www.anker.com'
      const domain =
        hostUrl.length > 2
          ? `.${hostUrl[1]}.${hostUrl[2]}`
          : window.location.hostname

      Cookies.set('initPopSales', 'hidden', {
        domain,
        expires: data?.intervalAfterClosing,
      })
    }
  }

  const aClick = (event) => {
    if (data?.intervalAfterOpening > 0) {
      const hostUrl = window?.location?.host?.split('.') || 'www.anker.com'
      const domain =
        hostUrl.length > 2
          ? `.${hostUrl[1]}.${hostUrl[2]}`
          : window.location.hostname
      Cookies.set('initPopSales', 'hidden', {
        domain,
        expires: data?.intervalAfterOpening,
      })
    }

    pageGTMEvent({
      event: 'eeEvent',
      eventCategory: 'promotion',
      eventAction: 'click',
      eventLabel: 'formPopBanner',
      nonInteraction: false,
      ecommerce: {
        promoClick: {
          promotions: [
            {
              id: 'formPopBanner',
              name: data?.dataLayerName,
              creative: data?.image,
              position: '',
            },
          ],
        },
      },
    })
  }

  useEffect(() => {
    const contentElement = contentRef.current

    if (contentElement && data?.lockBody && open) {
      disableBodyScroll(contentElement, {
        reserveScrollBarGap: true,
      })
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [open])

  useEffect(() => {
    if (initOpenPop()) {
      setOpen(true)
      pageGTMEvent({
        formPopBanner: {
          id: 'formPopBanner',
          name: data?.dataLayerName,
          creative: data?.image,
          position: '',
        },
      })
    }
  }, [])

  if (!data) return

  return (
    <motion.div
      id="formPopBanner"
      className={cn(s.popWrap, { [s.show]: open })}
      ref={contentRef}
    >
      <button
        className={cn(s.mask, { [s.lockBodyMask]: data?.lockBody })}
        onClick={() => closePop()}
      ></button>
      <div
        className={cn(s.popContainer, s.popMobile, s.center, {
          [s.center]: data?.position === 'center',
          [s.right_bottom]: data?.position === 'right_bottom',
          [s.left_bottom]: data?.position === 'left_bottom',
        })}
        style={{
          maxWidth: `${data?.maxWidth || 900}px`,
        }}
      >
        <button className={s.closeBtn} onClick={() => closePop()}>
          <XMarkIcon
            className={s.close}
            width="24"
            height="24"
            fill={data?.button_color || '#333'}
          />
        </button>

        <div
          className={cn(s.popContainerBox, s[data?.type], 'flex items-center')}
        >
          {data?.type === 'onlyImage' && (
            <a
              className={s.imageBox}
              href={`${setRelativeLink({ link: data?.href })}${
                data?.gtmref || ''
              }`}
              target={data?.target || '_self'}
              onClick={aClick}
            >
              <Picture
                source={`
                  ${data?.image}, 
                  ${data?.imageMobile || data?.image} 768
                `}
                alt={data?.alt || 'Picture of Pop'}
              />
            </a>
          )}

          {data?.type === 'subscribe' && (
            <>
              <div className={s.imageBox}>
                <Picture
                  source={`
                    ${data?.image}, 
                    ${data?.imageMobile || data?.image} 768
                  `}
                  alt={data?.alt || 'Picture of Pop'}
                />
              </div>
              <div className={s.txtBox}>
                {data?.title && (
                  <p
                    className={s.title}
                    dangerouslySetInnerHTML={{ __html: data?.title }}
                  ></p>
                )}
                {data?.desc && (
                  <p
                    className={s.desc}
                    dangerouslySetInnerHTML={{ __html: data?.desc }}
                  ></p>
                )}
                <div className={s.subscribeBox}>
                  <Subscribe
                    s={{
                      subscribeInput: s.subscribeInput,
                      outsizeButton: s.outsizeButton,
                      policy: s.policy,
                      checkbox_wrap: 'flex justify-center w-full',
                      tip: s.tip,
                    }}
                    outsideButton={true}
                    data={data}
                    metafields={metafields}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </motion.div>
  )
}

export default PopSales
